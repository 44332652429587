<template>
  <div class="turntable">
    <div class="head">
      <!-- 新老客户tab -->
      <div class="account_tab">
        <div @click="switchAccountType('0')">
          <img src="/subject/turntable/xin.png" />
          <img src="/subject/turntable/xin_g.png" class="guang" v-if="account_type === '0'" />
        </div>
        <div @click="switchAccountType('1')">
          <img src="/subject/turntable/lao.png" />
          <img src="/subject/turntable/lao_g.png" class="guang_2" v-if="account_type === '1'" />
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 转盘位置 -->
      <div
        :class="['turntableView', account_type === '0' ? 'turntableView_xin' : 'turntableView_lao']"
      >
        <LuckyWheel
          class="LuckyWheel"
          ref="myLucky"
          width="531px"
          height="531px"
          :prizes="prizes"
          :blocks="blocks"
          :buttons="buttons"
          :defaultConfig="defaultConfig"
          @start="startCallback"
          @end="endCallback"
        />
        <!-- 剩余次数 -->
        <div class="draws_number">剩余次数：{{ drawsNumber }}次</div>
        <!-- 抽奖指针 -->
        <!-- <div class="pointer">
          <img src="/subject/turntable/pointer.png" >
        </div> -->
      </div>
      <!-- 获得奖励列表 -->
      <div class="rewardsList">
        <img src="/subject/turntable/rewards_title.png" />
        <div class="rewards">
          <div v-for="item in this.turntablelogs" :key="item.id">{{ item.value }}</div>
          <div v-if="!userStatus">请登录后查看抽奖记录~</div>
        </div>
      </div>
      <div class="turntable_p">
        用户可前往用户中心 / App-我的 查看奖励到账情况
      </div>
      <div class="turntable_button">
        <div @click="live800">咨询客服</div>
        <div @click="questFunction(3, 0)">前往交易</div>
      </div>
      <!-- 任务 -->
      <div class="turntable_quest" v-if="userStatus">
        <!-- 攒次数 -->
        <div class="quest_title" style="color:#7a30da;">攒次数</div>
        <!-- 新客户 -->
        <div class="quest_list">
          <!-- <div class="quest_title">新用户</div> -->
          <template v-for="item in questList">
            <div class="quest_item" v-if="item.id === 1 && account_type === '0'">
              <div class="quest_item_left">注册后，可得{{ item.awardnum }}次转盘机会</div>
              <div v-if="!item.sendStatus" class="quest_item_right" @click="questFunction(item.id)">
                前往注册
              </div>
              <div class="quest_item_right" v-else>
                已完成
              </div>
            </div>
            <div class="quest_item" v-if="item.id === 2 && account_type === '0'">
              <div class="quest_item_left">首次入金后，可得{{ item.awardnum }}次转盘机会</div>
              <div v-if="!item.sendStatus" class="quest_item_right" @click="questFunction(item.id)">
                前往入金
              </div>
              <div v-else class="quest_item_right">
                已完成
              </div>
            </div>
            <div class="quest_item" v-if="item.id === 3 && account_type === '0'">
              <div class="quest_item_left">首次交易，可得{{ item.awardnum }}次转盘机会</div>
              <div v-if="!item.sendStatus" class="quest_item_right" @click="questFunction(item.id)">
                前往交易
              </div>
              <div v-else class="quest_item_right">
                已完成
              </div>
            </div>
            <div class="quest_item" v-if="item.id === 5 && account_type === '1'">
              <div class="quest_item_left">
                使用{{ item.requirenum }}积分，可得{{ item.awardnum }}次转盘机会
              </div>
              <div class="quest_item_right" @click="questFunction(item.id)">
                我的积分
              </div>
            </div>
            <div class="quest_item" v-if="item.id === 4 && account_type === '1'">
              <div class="quest_item_left">
                月净入金>={{ item.requirenum }}美金，可得{{ item.awardnum }}次转盘机会
              </div>
              <div class="quest_item_right" @click="questFunction(item.id)">
                前往入金
              </div>
            </div>
          </template>
        </div>
        <!-- 老客户 -->
        <!-- <div class="quest_list">
          <div class="quest_title">老用户</div>
          <div class="quest_item">
            <div class="quest_item_left">
              注册后，可得1次转盘机会
            </div>
            <div class="quest_item_right">
              前往注册
            </div>
          </div>
          <div class="quest_item">
            <div class="quest_item_left">
              注册后，可得1次转盘机会
            </div>
            <div class="quest_item_right">
              前往注册
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 中奖弹窗 -->
    <el-dialog :visible.sync="controlDialog">
      <div class="dialogTitle">
        恭喜你！
      </div>
      <div class="dialogCenter">{{ messageTitle }}</div>
      <div class="dialogButton" @click="closerDialog">
        开心收下
      </div>
    </el-dialog>
    <el-dialog :visible.sync="controlDialog2">
      <div class="dialogTitle">
        温馨提示
      </div>
      <div class="dialogCenter">请前往H5端或APP端进行交易</div>
      <div class="dialogButton" @click="closerDialog2">
        确定
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  searchId,
  queryUserStyle,
  searchUserNum,
  luckDraw,
  searchUserHistory,
  getQuestList,
  exchangePoints,
} from '../../../../api/info';
import awardId from './global';

export default {
  data() {
    return {
      awardId,
      userStatus: 0,
      userKey: '',
      userAccount: '',
      messageTitle: '',
      account_type: '0',
      drawsNumber: 0,
      screenWidth: null,
      cjIndex: null,
      controlDialog: false,
      controlDialog2: false,
      turntablelogs: [],
      questList: [],
      // 转盘插件变量
      blocks: [
        {
          padding: '30px',
          imgs: [
            {
              src: '/subject/turntable/pc_xin_bj.png',
              width: '531px',
              height: '531px',
              top: '0px',
              rotate: true,
            },
          ],
        },
      ],
      prizes: [],
      buttons: [
        {
          radius: '25%',
          pointer: true,
          imgs: [
            {
              src: `/subject/turntable/pointer.png`,
              top: '-325px',
              width: '293px',
              height: '325px',
            },
            {
              src: `/subject/turntable/zpButton.gif`,
              top: '-80px',
              width: '145px',
              height: '145px',
            },
          ],
        },
      ],
      defaultConfig: {
        offsetDegree: -22,
      },
    };
  },
  created() {
    this.$emit('controlShow', true);
    // 判断用户是否是自己登陆
    if (this.$route.query.turntableKey) {
      sessionStorage.setItem('turntableKey', this.$route.query.turntableKey.replace(/ /g, '+'));
    }
    if (!sessionStorage.getItem('account')) {
      if (!this.$route.query.account) {
        this.userStatus = 0;
        this.account_type = '0';
        this.getPrizeList(awardId.newUserID);
        getQuestList();
      } else {
        sessionStorage.setItem('account', this.$route.query.account);
        this.userAccount = this.$route.query.account;
        this.userKey = sessionStorage.getItem('turntableKey');
        this.queryUserAccount(this.userKey);
        this.getQuestList();
      }
    } else {
      this.userStatus = 1;
      this.userKey = sessionStorage.getItem('turntableKey');
      this.queryUserAccount(this.userKey);
      this.getQuestList();
    }
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth < 500) {
      this.$router.push({
        name: 'turntableWap',
      });
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: function(n) {
      if (n <= 500) {
        this.$router.push({
          name: 'turntableWap',
        });
      }
    },
    account_type: function(type) {
      if (type === '0') {
        this.blocks[0].imgs[0].src = '/subject/turntable/pc_xin_bj.png';
      } else {
        this.blocks[0].imgs[0].src = '/subject/turntable/pc_lao_bj.png';
      }
    },
  },
  methods: {
    switchAccountType(type) {
      this.account_type = type;
      this.getPrizeList(Number(this.account_type) ? awardId.oldUserID : awardId.newUserID);
      if (sessionStorage.getItem('turntableKey')) {
        this.initUserNum(
          sessionStorage.getItem('account'),
          Number(this.account_type) ? awardId.oldUserID : awardId.newUserID
        );
        this.initUserHistory(sessionStorage.getItem('account'));
      }
    },
    // 抽奖
    startCallback() {
      if (!sessionStorage.getItem('account')) {
        this.loginMethod();
      } else {
        if (this.drawsNumber > 0) {
          let initData = {
            Account: Number(sessionStorage.getItem('account')),
            TurntableId: Number(this.account_type) ? awardId.oldUserID : awardId.newUserID,
          };
          luckDraw(initData).then((res) => {
            if (res.status === 0) {
              if (res.turntableLog) {
                // 调用抽奖组件的play方法开始游戏
                this.$refs.myLucky.play();
                // if (res.turntableLog.awardname.indexOf('赠')!==-1) {
                //   this.messageAll.messagePPP = res.turntableLog.awardname.split('赠')[0];
                // } else {
                //   this.messageAll.messagePPP = res.turntableLog.awardname;
                // }
                if (this.account_type === '0') {
                  switch (res.turntableLog.awardname) {
                    case '$20金银交易卡券':
                      this.cjIndex = 0;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '$20金银交易卡券';
                      break;
                    case '$50金银交易卡券':
                      this.cjIndex = 1;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '$50金银交易卡券';
                      break;
                    case '$100金银交易卡券':
                      this.cjIndex = 2;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '$100金银交易卡券';
                      break;
                    case '$200金银交易卡券':
                      this.cjIndex = 3;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '$200金银交易卡券';
                      break;
                    case '100美元现金':
                      this.cjIndex = 4;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '100美元现金';
                      break;
                    case '100美元赠金':
                      this.cjIndex = 5;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '100美元赠金';
                      break;
                    case '50美元赠金':
                      this.cjIndex = 6;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '50美元赠金';
                      break;
                    case '￥50微信红包':
                      this.cjIndex = 7;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '￥50微信红包';
                      break;
                  }
                } else {
                  switch (res.turntableLog.awardname) {
                    case '999美元赠金':
                      this.cjIndex = 0;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '999美元赠金';
                      break;
                    case '199美元赠金':
                      this.cjIndex = 1;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '199美元赠金';
                      break;
                    case '99美元赠金':
                      this.cjIndex = 2;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '99美元赠金';
                      break;
                    case '￥100现金':
                      this.cjIndex = 3;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '￥100现金';
                      break;
                    case '￥10现金':
                      this.cjIndex = 4;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '￥10现金';
                      break;
                    case '￥5现金':
                      this.cjIndex = 5;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '￥5现金';
                      break;
                    case '￥2现金':
                      this.cjIndex = 6;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '￥2现金';
                      break;
                    case '再来一次':
                      this.cjIndex = 7;
                      this.$refs.myLucky.stop(this.cjIndex);
                      this.messageTitle = '再来一次';
                      break;

                    // case '299美元赠金':
                    //   this.cjIndex = 1
                    //   this.$refs.myLucky.stop(this.cjIndex)
                    //   this.messageTitle = '299美元赠金'
                    //   break
                    // case '199美元赠金':
                    //   this.cjIndex = 2
                    //   this.$refs.myLucky.stop(this.cjIndex)
                    //   this.messageTitle = '199美元赠金'
                    //   break
                    // case '99美元赠金':
                    //   this.cjIndex = 3
                    //   this.$refs.myLucky.stop(this.cjIndex)
                    //   this.messageTitle = '99美元赠金'
                    //   break
                    // case '￥100现金':
                    //   this.cjIndex = 4
                    //   this.$refs.myLucky.stop(this.cjIndex)
                    //   this.messageTitle = '￥100现金'
                    //   break
                    // case '￥10现金':
                    //   this.cjIndex = 5
                    //   this.$refs.myLucky.stop(this.cjIndex)
                    //   this.messageTitle = '￥10现金'
                    //   break
                    // case '￥5现金':
                    //   this.cjIndex = 6
                    //   this.$refs.myLucky.stop(this.cjIndex)
                    //   this.messageTitle = '￥5现金'
                    //   break
                    // case '￥2现金':
                    //   this.cjIndex = 7
                    //   this.$refs.myLucky.stop(this.cjIndex)
                    //   this.messageTitle = '￥2现金'
                    //   break
                  }
                }
              } else {
                this.$message({
                  message: res.msg,
                  type: 'warning',
                });
              }
            } else {
              this.$message({
                message: '抽取奖励失败！',
                type: 'warning',
              });
            }
          });
        } else {
          this.$message({
            message: '您无可用的抽奖次数！',
            type: 'warning',
          });
        }
      }
    },
    // 抽奖结束
    endCallback() {
      this.cjIndex = null;
      this.controlDialog = true;
      this.initUserNum(
        sessionStorage.getItem('account'),
        Number(this.account_type) ? awardId.oldUserID : awardId.newUserID
      );
    },
    // 登录
    loginMethod() {
      window.location.href= window.location.origin + '/uc/login?'+'turntable=turntable';
      // window.location.href = 'http://test.www.rlcproltd.com' + '/uc/login?' + 'turntable=turntable'
      // window.location.href = 'https://www.rlcproltd.net' + '/uc/login?' + 'turntable=turntable';
    },
    // 查询转盘
    getPrizeList(id) {
      searchId(id).then((res) => {
        if (res.status === 0) {
          this.prizes = [];
          res.awardlist.forEach((item, index) => {
            const prize = {
              imgs: [
                {
                  src: item.awardicon,
                  top: '10px',
                },
              ],
            };
            this.prizes.push(prize);
          });
        } else {
          this.$message({
            message: '获取转盘信息失败!',
            type: 'warning',
          });
        }
      });
    },
    // 查询抽奖次数
    initUserNum(attr, id) {
      let initData = {
        TurntablenumStatus: {
          accountid: attr,
          turntableid: id,
          platform: 1,
        },
      };
      searchUserNum(initData).then((res) => {
        if (res.status === 0) {
          this.drawsNumber = res.surplusnum;
        }
      });
    },
    // 查询账户是否为老客户
    queryUserAccount(key) {
      queryUserStyle({}, key).then((res) => {
        if (res.code === 0) {
          this.account_type = res.data ? '1' : '0';
          this.getPrizeList(res.data ? awardId.oldUserID : awardId.newUserID);
          this.initUserNum(
            sessionStorage.getItem('account'),
            res.data ? awardId.oldUserID : awardId.newUserID
          );
          this.initUserHistory(sessionStorage.getItem('account'));
        }
      });
    },
    // 查询任务列表
    getQuestList() {
      getQuestList({}, this.userKey).then((res) => {
        if (res.IsSuccess) {
          this.questList = res.Data;
        }
      });
    },
    // 查询历史抽奖结果
    initUserHistory(attr) {
      let initData = {
        TurntablenumStatus: {
          accountid: attr,
          turntableid: Number(this.account_type) ? awardId.oldUserID : awardId.newUserID,
          platform: 1,
        },
      };
      searchUserHistory(initData).then((res) => {
        if (res.status === 0) {
          let craigArr = [];
          res.turntablelogs.map((item) => {
            craigArr.push({
              value: item.awardname,
              id: item.id,
            });
          });
          let r = craigArr.filter((item) => {
            return item.value != '未抽中';
          });
          this.turntablelogs = r;
          this.userStatus = 1;
        }
      });
    },
    // 客服链接
    live800() {
      window.open(
        'https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377'
      );
    },
    closerDialog() {
      this.controlDialog = false;
      this.initUserHistory(sessionStorage.getItem('account'));
    },
    closerDialog2() {
      this.controlDialog2 = false;
    },
    questFunction(id) {
      if (id === 1) {
        // 注册跳转
        window.location.href= window.location.origin + '/uc/signUp/real';
        // window.location.href = 'http://test.www.rlcproltd.com' + '/uc/signUp/real';
      } else if (id === 2 || id === 4) {
        // 首次入金
        window.location.href= window.location.origin + '/uc/assets/deposit';
        // window.location.href = 'http://test.www.rlcproltd.com' + '/uc/assets/deposit';
      } else if (id === 3) {
        // 首次交易
        this.controlDialog2 = true;
      } else if (id === 5) {
        // 兑换积分
        exchangePoints({}, this.userKey).then((res) => {
          if (res.IsSuccess) {
            this.$message({
              message: res.Data,
              type: 'success',
            });
          } else {
            this.$message({
              message: res.Data,
              type: 'error',
            });
          }

          this.initUserNum(
            sessionStorage.getItem('account'),
            Number(this.account_type) ? awardId.oldUserID : awardId.newUserID
          );
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.head {
  width: 100%;
  height: 821px;
  background-repeat: no-repeat;
  background-image: url('/subject/turntable/banner.png');
  background-size: cover;
  position: relative;
  z-index: 2;
  margin-bottom: -136px;
  .account_tab {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: -90px;
    left: 0;
    > div {
      position: relative;
      &:nth-child(2) {
        margin-top: -2px;
      }
    }
    .guang {
      position: absolute;
      left: 50%;
      top: -100px;
      margin-left: -210px;
      z-index: -1;
    }
    .guang_2 {
      position: absolute;
      left: 0;
      top: -100px;
      margin-left: -90px;
      z-index: -1;
    }
  }
}
.content {
  width: 100%;
  // height: 2381px;
  padding-bottom: 80px;
  background-repeat: no-repeat;
  background-image: url('/subject/turntable/bj.png');
  background-size: cover;
  position: relative;
  z-index: 1;
  padding-top: 280px;
  .turntableView {
    width: 660px;
    height: 807px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: 0 auto;
    // top: 280px;
    .draws_number {
      width: 340px;
      font-size: 32px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 67px;
      position: absolute;
      bottom: 100px;
      left: 50%;
      margin-left: -170px;
    }
    .pointer {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -150px;
      z-index: 1;
    }
  }
  .turntableView_xin {
    background-image: url('/subject/turntable/xin_bj.png');
  }
  .turntableView_lao {
    background-image: url('/subject/turntable/lao_bj.png');
  }
  .rewardsList {
    width: 723px;
    height: 308px;
    background-repeat: no-repeat;
    background-image: url('/subject/turntable/rewards_bj.png');
    background-size: cover;
    margin: 48px auto 90px;
    > img {
      display: block;
      margin: 0 auto 13px;
    }
    .rewards {
      height: 174px;
      margin: 0 22px;
      padding: 13px 0;
      overflow-y: auto;
      font-size: 24px;
      color: #796c9d;
      text-align: center;
      > div {
        margin-bottom: 15px;
      }
    }
  }
  .turntable_p {
    font-size: 24px;
    color: #fff;
    text-align: center;
    margin-bottom: 60px;
  }
  .turntable_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    > div {
      width: 340px;
      height: 85px;
      line-height: 85px;
      text-align: center;
      font-size: 35px;
      color: #fff;
      font-weight: bold;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      &:nth-child(1) {
        background-image: url('/subject/turntable/button_1.png');
        margin-right: 75px;
      }
      &:nth-child(2) {
        background-image: url('/subject/turntable/button_2.png');
      }
    }
  }
  .turntable_quest {
    width: 603px;
    min-height: 548px;
    background-repeat: no-repeat;
    background-image: url('/subject/turntable/quest_bj.png');
    background-size: 100% 100%;
    margin: 0 auto;
    padding: 50px 60px;
    .quest_title {
      font-size: 24px;
      margin-bottom: 40px;
    }
    .quest_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;
      .quest_item_left {
        font-size: 24px;
        color: #796c9d;
      }
      .quest_item_right {
        border: 1px solid #796c9d;
        width: 130px;
        height: 42px;
        font-size: 20px;
        color: #796c9d;
        text-align: center;
        line-height: 42px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}
.LuckyWheel {
  position: relative;
  top: 60px;
  left: 62px;
}
.dialogTitle {
  font-size: 40px;
  color: #000000;
  margin-bottom: 40px;
  text-align: center;
  font-weight: bold;
}
.dialogCenter {
  font-size: 24px;
  color: #3f3f3f;
  text-align: center;
  margin-bottom: 90px;
}
.dialogButton {
  width: 340px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  font-size: 35px;
  color: #fff;
  font-weight: bold;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  background-image: url('/subject/turntable/button_2.png');
  margin: 0 auto;
}
</style>
